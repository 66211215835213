let burger = document.querySelector('.js-menu__btn')
let menu = document.querySelector('.js-asides')
let burgerClose = document.querySelector('.js-menu__close')
let headerOverlay = document.querySelector('.js-header-overlay')
let body = document.querySelector('body')

if (window.matchMedia("(max-width: 768px)").matches) {
  burger.addEventListener('click', function () {
    menu.classList.toggle('is-active')
    headerOverlay.classList.toggle('is-active')
    body.classList.toggle('no-scroll')
  })
}
  
if(window.matchMedia("(max-width: 768px)").matches){
  burgerClose.addEventListener('click', function(){
    menu.classList.toggle('is-active')
    headerOverlay.classList.toggle('is-active')
    body.classList.remove('no-scroll')
  })
}


headerOverlay.addEventListener('click', function(event){
  if(event.target.className === headerOverlay.className){
    menu.classList.remove('is-active')
    headerOverlay.classList.remove('is-active')
    body.classList.remove('no-scroll')
    body.classList.remove('move')
  }
})

